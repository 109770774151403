<style>
    #upload-img-new .btn-remove-img:hover {
        background-color: #ffd1c0;
    }

    #upload-img-new .validateMessage {
        color: #ee9800;
    }

    #upload-img-new .doc-upload{
        border:2px dashed #d0e9ff;
        display: block;
        padding: 20px;
        background: #fff;
        border-radius: 0.5rem;
        min-height: 230px;
    }

    #upload-img-new .doc-upload .title {
        color: #196bb6;
        text-align: center;
    }

    #upload-img-new .doc-upload .preview {
        text-align: center;
        margin:25px 0;
        display:flex;
        align-items: center;
        justify-content: center;
    }

    #upload-img-new .doc-upload .preview .img-item, #upload-img-new .doc-upload .preview-grid .img-item{
        display: inline-block;
        padding: 10px;
        margin: 0 0 10px 0;
        border:1px solid #c6d6e7;
        border-radius:0.5rem;
        position: relative;
    }

    #upload-img-new .doc-upload .desc{
        text-align: center;
        color: #9c9c9c;
        font-size: 11px;
    }

    #upload-img-new .doc-upload .preview .img-item img, #upload-img-new .doc-upload .preview .error img,   #upload-img-new .doc-upload .preview-grid img, #upload-img-new .doc-upload .preview-grid .error img{
        height: 80px;
        max-width: 100%;
    }

    #upload-img-new .doc-upload .preview .img-item a.remove, #upload-img-new .doc-upload .preview-grid .img-item a.remove{
        position: absolute;
        right: 0;
        bottom: -23px;
        background: #d5d5d5;
        padding: 3px 3px;
        border-radius: 50%;
        text-decoration: none;
    }

    #upload-img-new .doc-upload .preview .img-item a.remove svg, #upload-img-new .doc-upload .preview-grid .img-item a.remove svg{
        margin-top: 0px!important;
    }

    #upload-img-new .req-mandatory-photo{
        color: red;
    }

    #inspection-report #preview-modal #btn-close-preview {
        right: 0px;
        top: 0px;
    }
</style>

<template>
    <div id="upload-img-new">
        <div class="row text-center">
            <div class="col-xs-12 col-md-12">
                <p class="my-3 validateMessage" id="validateMessage" style="font-size: .875rem;">
                    {{$t("validateMessage")}}
                </p>
            </div>
            <div v-for="(obj, tagKey) in vehicleSide" :class="getCssByKey(tagKey)" :key="tagKey">
                <div class="mb-4">
                    <div class="doc-upload">
                        <div class="title">
                            {{$t("tagName."+ tagKey )}} <span class="req-mandatory-photo">{{mandatoryPhotoKey.find(x => x === tagKey) ? "*":""}}</span>
                        </div>
                        <div class="preview">
                            <span v-if="isImgLoading || vehicleSide[tagKey].showLoader" :id="'spinner-'+tagKey" class="spinner-border spinner-border-lg loading text-info m-3" role="status" aria-hidden="true"></span>
                            <div class="img-item" v-else>
                                <div v-if="obj != null && obj.thumbnailUrl">
                                    <img :src="obj.thumbnailUrl" class="bi bi-images my-4" @click="openPreviewModal(obj)">
                                    <button type="button" class="btn btn-remove-img btn-sm btn-block bg-gradient-basic showmodal" :id="'delete-'+tagKey" @click="showModalConfirmDelete(tagKey)">
                                        {{$t("delete") }}
                                    </button>
                                </div>
                                <div v-else class="img">
                                    <svg v-if="!obj.overlay" width="75" height="75" viewBox="0 0 16 16" class="bi bi-images my-4" fill="#ced6e0" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M12.002 4h-10a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1zm-10-1a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-10zm4 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                        <path fill-rule="evenodd" d="M4 2h10a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1v1a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2h1a1 1 0 0 1 1-1z"/>
                                    </svg>
                                    <img v-else :src="publicPath + obj.overlay" class="bi bi-images my-4"> 
                                    <button @click="browseImage(tagKey)" id="btn-AddDocument" data-selenium-name="add-document" type="button" class="btn btn-primary btn-sm btn-block fileinput-button bg-gradient-info">
                                        {{$t("upload")}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>

            <!-- Accessory -->
            <div class="col-12" v-if="inspection.product_type__c === 'Car' || inspection.product_type__c === 'CarEV'">
                <div class="doc-upload">
                    <div class="title">{{$t("tagName.vehicleAccessory")}} <span class="req-mandatory-photo">{{mandatoryPhotoKey.find(x => x === 'accessory') ? "*":""}}</span></div> 
                    <div class="row">
                        <div v-for="(obj, idx) in vehicleAcsPhotos" :key="idx" class="col-12 col-md-6 col-lg-3">
                            <div class="mb-4">
                                <div class="preview">
                                    <span v-if="isImgLoading || isShowAcsLoading" :id="'processing-vehicle-acs-'+idx" class="spinner-border spinner-border-lg loading text-info m-3" role="status" aria-hidden="true"></span>
                                    <div class="img-item" v-else>
                                        <div v-if="obj != null && obj.thumbnailUrl">
                                            <img :src="obj.thumbnailUrl" class="bi bi-images my-4" @click="openPreviewModal(obj)">
                                            <button type="button" class="btn btn-remove-img btn-sm btn-block bg-gradient-basic showmodal" :id="'delete-accessory-'+idx" @click="showModalConfirmDelete('accessory-'+idx)">
                                                {{ $t("delete") }}
                                            </button>
                                        </div>
                                        <div v-else class="img">
                                            <svg v-if="!obj.overlay" width="75" height="75" viewBox="0 0 16 16" class="bi bi-images my-4" fill="#ced6e0" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" d="M12.002 4h-10a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1zm-10-1a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-10zm4 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                                <path fill-rule="evenodd" d="M4 2h10a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1v1a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2h1a1 1 0 0 1 1-1z"/>
                                            </svg>
                                            <img v-else :src="publicPath + obj.overlay" class="bi bi-images my-4">
                                            <button @click="browseImage('accessory')" :id="'btn-add-photo-vehicle-accessory-' + idx" :data-selenium-name="'add-photo-vehicle-accessory-' + idx" type="button" class="btn btn-primary btn-sm btn-block fileinput-button bg-gradient-info">
                                                {{$t("upload")}}
                                            </button>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-12">
                            <p class="my-3 validateMessage" id="validateMessage" style="font-size: .875rem;">
                                {{$t("validateMessage")}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12" v-if="inspection.product_type__c === 'Car' || inspection.product_type__c === 'CarEV'">&nbsp;</div>

            <!-- Vehicle Damage -->
            <div class="col-12">
                <div class="doc-upload">
                    <div class="title">{{$t("tagName.vehicleDamage")}}</div>
                    <div class="row">
                        <div v-for="(obj, idx) in vehicleDmgPhotos" :key="idx" class="col-12 col-md-6 col-lg-3">
                            <div class="mb-4">
                                <div class="preview">
                                    <span v-if="isImgLoading || isShowDmgLoading" :id="'processing-vehicle-dmg-'+ idx" class="spinner-border spinner-border-lg loading text-info m-3" role="status" aria-hidden="true"></span>
                                    <div class="img-item" v-else>
                                        <div v-if="obj != null && obj.thumbnailUrl">
                                            <img :src="obj.thumbnailUrl" class="bi bi-images my-4" @click="openPreviewModal(obj)">
                                            <button type="button" class="btn btn-remove-img btn-sm btn-block bg-gradient-basic showmodal" :id="'delete-damage-'+idx" @click="showModalConfirmDelete('damage-'+idx)">
                                                {{ $t("delete") }}
                                            </button>
                                        </div>
                                        <div v-else class="img">
                                            <svg v-if="!obj.overlay" width="75" height="75" viewBox="0 0 16 16" class="bi bi-images my-4" fill="#ced6e0" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" d="M12.002 4h-10a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1zm-10-1a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-10zm4 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                                <path fill-rule="evenodd" d="M4 2h10a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1v1a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2h1a1 1 0 0 1 1-1z"/>
                                            </svg>
                                            <img v-else :src="publicPath + obj.overlay" class="bi bi-images my-4">
                                            <button @click="browseImage('damage')" :id="'btn-add-photo-vehicle-damage-' + idx" :data-selenium-name="'add-photo-vehicle-damage-' + idx" type="button" class="btn btn-primary btn-sm btn-block fileinput-button bg-gradient-info">
                                                {{$t("upload")}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-12">
                            <p class="my-3 validateMessage" id="validateMessage" style="font-size: .875rem;">
                                {{$t("validateMessage")}}
                            </p>
                        </div>
                    </div>
                </div>  
            </div>
        </div>

        <div id="inspection-dropzone" class="d-none" />
        <div id="dropZoneForm" class="d-none">
            <input id="myFile" type="file" class="d-none" accept="image/*">
        </div>   

        <Teleport to="#preview-inspection-photo-teleport-target">
            <div id="preview-modal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="previewModalTitle" aria-hidden="true" style="background-color: #000000; display: none;">
                <div class="modal-dialog modal-lg modal-dialog-centered modal2detail4pk" role="document" id="dialogPreview">
                    <div class="modal-content">
                        <div class="modal-body">
                            <img :src="showItem.thumbnailUrl" style="width: 100%; max-width: none">
                            <button id="btn-close-preview" type="button" class="btn-close position-absolute" data-bs-dismiss="modal" data-bs-target="#detail_modal" aria-label="Close">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Teleport>

        <Teleport to="#confirm-delete-teleport-target">
            <div id="modal-confirm-delete" data-selenium-name="confirm-delete-modal" data-bs-backdrop="static" data-bs-keyboard="false"
                tabindex="-1" role="dialog" aria-labelledby="ModalConfirmDeleteTitle" class="modal fade " aria-modal="true" style="display: none;">
                <div role="document" class="modal-dialog modal-md modal2detail4pk">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="confirm-delete-modal-title">{{$t("confirmDelete")}}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="cancelDelete()"></button>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn text-white bg-gradient-danger" data-bs-dismiss="modal" @click="deletePhoto()">{{$t("delete")}}</button>
                            <button type="button" class="btn bg-gradient-basic" data-bs-dismiss="modal" @click="cancelDelete()">{{$t("cancel")}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </Teleport>

        <Teleport to="#error-upload-inspection-photo-teleport-target">
            <div id="modal-upload-insp-photo-error" data-selenium-name="upload-error-modal" data-bs-backdrop="static" data-bs-keyboard="false"
                tabindex="-1" role="dialog" aria-labelledby="ModalErrorUploadInspectionPhoto" class="modal fade " aria-modal="true" style="display: none;">
                <div role="document" class="modal-dialog modal-md modal2detail4pk">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="infoModalLabel">{{$t("uploadError")}}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn bg-gradient-primary" data-bs-dismiss="modal" >{{$t("close")}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </Teleport>

        <Teleport to="#error-required-mandatory-photo-teleport-target">
            <div id="modal-required-mandatory-photo-error" data-selenium-name="required-mandatory-modal" data-bs-backdrop="static" data-bs-keyboard="false"
                tabindex="-1" role="dialog" aria-labelledby="ModalErrorRequiredMandatoryPhoto" class="modal fade " aria-modal="true" style="display: none;">
                <div role="document" class="modal-dialog modal-md modal2detail4pk">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="mandatoryLabel">{{$t("warning")}}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-md-3 col-sm-3">
                                        <svg viewBox="0 0 24 24" width="100" height="100" stroke="#f44336" stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1 mb-3">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <line x1="15" y1="9" x2="9" y2="15"></line>
                                            <line x1="9" y1="9" x2="15" y2="15"></line>
                                        </svg>
                                    </div>
                                    <div class="col-md-9 col-sm-9"><p>{{ $t("mandatoryErrMsg") }}</p></div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn bg-gradient-primary" data-bs-dismiss="modal" >{{$t("close")}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </Teleport>

        <Teleport to="#submit-result-teleport-target">
            <div id="modal-submit-photo-result" data-selenium-name="submit-photo-result-modal" data-bs-backdrop="static" data-bs-keyboard="false"
                tabindex="-1" role="dialog" aria-labelledby="ModalSubmitPhotoResult" class="modal fade " aria-modal="true" style="display: none;">
                <div role="document" class="modal-dialog modal-md modal2detail4pk">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modal-submit-result-Label">{{$t("warning")}}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="container-fluid">
                                <div class="row">
                                    <div v-if="isSuccessSubmit" class="col-md-4 col-sm-3 text-center">
                                        <svg viewBox="0 0 24 24" width="65" height="65" stroke="#35d33f" stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1 mb-3">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <line x1="15" y1="9" x2="11" y2="15"></line>
                                            <line x1="9" y1="12" x2="11" y2="15"></line>
                                        </svg>
                                    </div>
                                    <div v-else class="col-md-4 col-sm-3 text-center">
                                        <svg viewBox="0 0 24 24" width="65" height="65" stroke="#f44336" stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1 mb-3">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <line x1="15" y1="9" x2="9" y2="15"></line>
                                            <line x1="9" y1="9" x2="15" y2="15"></line>
                                        </svg>
                                    </div>
                                    <div class="col-md-8 col-sm-9 d-flex align-items-center">
                                        <p class="lead" v-if="isSuccessSubmit">{{ $t("submitSuccess") }}</p>
                                        <p class="lead" v-else>{{ $t("submitFailed") }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn bg-gradient-primary" data-bs-dismiss="modal" >{{$t("close")}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </Teleport>

    </div>
</template>

<script>
import Global from "@/stores/GlobalVariables.js";
import axios from "axios";
import mixin from "@/components/Mixin";
import Dropzone from "../../public/js/dropzone.min.js";

export default {
    mixins: [mixin],
    name: 'UploadInspectionPhoto',
    data() {
        return {
            Global,
            dmsToken: null,
            photoDropzone: null,
            vehicleSide: null,
            vehicleAcsPhotos: [],
            vehicleDmgPhotos: [],
            imageList: null,
            photoTagToDelete: null,
            s3Tag: {
                "front" : "Vehicle Front",
                "back" : "Vehicle Back",
                "left" : "Vehicle Left side",
                "right" : "Vehicle Right side",
                "roof" : "Vehicle Roof",
                "engine" : "Vehicle Engine",
                "odometer" : "Odometer reading",
                "dashcam" : "Vehicle Camera",
                "accessory" : "Vehicle accessory",
                "damage" : "Vehicle Damage",
                "charger" : "Home wall charger ID",
                "battery" : "Battery ID"
            },
            isImgLoading: true,
            isShowAcsLoading: false,
            isShowDmgLoading: false,
            addQueue: {},
            showItem: {},
            tempUploadTag: null,
            defaultOverlay: {},
            mandatoryPhotoKey: [],
            uploadTag: {},
            isSuccessSubmit: false
        }
    },
    props: {
        oppName: String,
        oppId: String,
        vid_sfid: String,
        vid_status: String,
        inspection: Object,
        inspectionStatus: String
    },
    created() {
    },
    methods: {
        cancelDelete: function(){
            var vm = this;
            vm.photoTagToDelete = null;
        },
        deletePhoto: function(){
            let vm = this;
            let photoToDelete;

            vm.topLoading("show");

            if(vm.photoTagToDelete.includes('accessory')){
                photoToDelete = vm.vehicleAcsPhotos[vm.photoTagToDelete.split('-')[1]];
            }else if(vm.photoTagToDelete.includes('damage')){
                photoToDelete = vm.vehicleDmgPhotos[vm.photoTagToDelete.split('-')[1]];
            }else{
                photoToDelete = vm.vehicleSide[vm.photoTagToDelete];
            }

            photoToDelete.name = decodeURI(photoToDelete.name);
            photoToDelete.s3Key = decodeURI(photoToDelete.s3Key);
            photoToDelete.url = decodeURI(photoToDelete.thumbnailUrl);
            
            vm.getDmsToken(function(){
                axios.post(Global.uploadDocument.url + "/update/deleteFile", photoToDelete,
                {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        "Authorization": "Bearer " + vm.dmsToken,
                        'Accept': "application/json"
                    }
                })
                .then(() => {
                    var modal = vm.modal("#model-confirm-delete");
                    if(modal){
                        modal.hide();
                    }
                    vm.replaceDeletedPhotoByOverlay();
                    if(vm.uploadTag[photoToDelete.name]){
                        delete vm.uploadTag[photoToDelete.name];
                    }
                }, (response) => {
                    console.log(response);
                }).finally(function(){
                    vm.topLoading("hide");
                });
            });
        },
        replaceDeletedPhotoByOverlay: function(){
            let vm = this;
            if(vm.photoTagToDelete.includes('accessory')){
                let index = vm.photoTagToDelete.split('-')[1];
                vm.vehicleAcsPhotos[index] = JSON.parse(JSON.stringify(vm.defaultOverlay["accessory"][index]));
            }else if(vm.photoTagToDelete.includes('damage')){
                let index = vm.photoTagToDelete.split('-')[1];
                vm.vehicleDmgPhotos[index] = JSON.parse(JSON.stringify(vm.defaultOverlay["damage"][index]));
            }else{
                vm.vehicleSide[vm.photoTagToDelete] = JSON.parse(JSON.stringify(vm.defaultOverlay["side"][vm.photoTagToDelete]));
            }
        },
        showModalConfirmDelete: function(tagToDelete) {
            var vm = this;
            vm.photoTagToDelete = tagToDelete;
            var modal = vm.modal("#modal-confirm-delete");
            if(modal){
                modal.show();
            }
        },
        // submitPhotoThenClose: async function(){
        //     let vm = this;
        //     if(await vm.submitPhoto()){
        //         vm.topLoading("hide"); 
        //         var modal = vm.modal("#inspection_detail_modal");
        //         if(modal){
        //             modal.hide();
        //         }
        //         return true;
        //     }
        // },
        submitWithoutClose: async function(){
            let vm = this;
            if(await this.submitPhoto()){
                this.topLoading("hide");
                vm.inspection.inspectionStatus = 'In Progress';
                setTimeout(()=>{
                    vm.$parent.search();                    
                }, 2500); 
                vm.isSuccessSubmit = true;
                var modalSubmitResult = vm.modal("#modal-submit-photo-result");
                if(modalSubmitResult){
                    modalSubmitResult.show();
                    setTimeout(function(){
                        modalSubmitResult.hide();
                    }, 1500);
                }
            }
        },
        submitPhoto: async function(){
            let vm = this;
            vm.topLoading("show");
            if(vm.isMandatoryPhotoRequired()){
                vm.topLoading("hide");
                var modal = vm.modal("#modal-required-mandatory-photo-error");
                if(modal){
                    modal.show();
                }
                return false;
            }else{
                if(await vm.tagPhoto(true)){
                    return true;
                } else {
                    vm.topLoading("hide");
                    vm.isSuccessSubmit = false;
                    var modalSubmitResult = vm.modal("#modal-submit-photo-result");
                    if(modalSubmitResult){
                        modalSubmitResult.show();                            
                    }
                    return false;
                }
            }
        },
        isMandatoryPhotoRequired: function(){
            let vm = this;
            for(let key of vm.mandatoryPhotoKey){
                if(key !== 'accessory' && !vm.vehicleSide[key]?.thumbnailUrl){
                    return true;
                }
                if(key === 'accessory'){
                    if(vm.vehicleAcsPhotos.filter(item => item.thumbnailUrl).length === 0){
                        return true;
                    }
                }
            }
            return false;
        },
        browseImage: function(tag){
            const vm = this;
            vm.tempUploadTag = tag;
            document.querySelector("#myFile").click();
        },
        openPreviewModal: function (img) {
            var vm = this;
            vm.showItem = img;
            var modal = this.modal("#preview-modal");
            if( modal ){
                modal.show();
            }
        },
        loadInspectionImages: async function(){
            const vm = this;
            vm.initialImageOverlay(vm.inspection?.product_type__c);
            try {
                const res = await axios.get(Global.apiUrl + "/inspectionReport/details/" + vm.oppName + "/" + vm.vid_sfid + "/" + vm.inspectionStatus);
                if(res){
                    res.data.vehicleInspectionPhotos.forEach(photo => {
                        if(photo.tags.find(uploadBy => uploadBy === "Upload by Intermediary")){
                            photo.tags.forEach(imgTag => {
                                if(imgTag === "Vehicle accessory"){
                                    let availableIdx = vm.vehicleAcsPhotos.findIndex(x => Object.prototype.hasOwnProperty.call(x, 'overlay'));
                                    if(availableIdx > -1){
                                        vm.vehicleAcsPhotos[availableIdx] = photo;
                                    }
                                }else if(imgTag === "Vehicle Damage"){
                                    let availableIdx = vm.vehicleDmgPhotos.findIndex(x => Object.prototype.hasOwnProperty.call(x, 'overlay'));
                                    if(availableIdx > -1){
                                        vm.vehicleDmgPhotos[availableIdx] = photo;
                                    }
                                }else{
                                    Object.keys(vm.s3Tag).find(s3TagKey => {
                                        if(vm.s3Tag[s3TagKey] === imgTag){
                                            vm.vehicleSide[s3TagKey] = photo;
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            } catch (error){
                console.error(error);
            } finally {
                vm.isImgLoading = false;
                vm.isShowAcsLoading = false;
                vm.isShowDmgLoading = false;
                vm.topLoading("hide");
            }
        },
        initialImageOverlay: function(productType){
            let vm = this;
            if(productType === 'Car'||productType === 'CarEV'){
                vm.vehicleSide = {
                    "front": { "overlay": "img/inspection-overlay/car/front.png" },
                    "back": { "overlay": "img/inspection-overlay/car/back.png" },
                    "left":{ "overlay": "img/inspection-overlay/car/left.png" },
                    "right": { "overlay": "img/inspection-overlay/car/right.png" },
                    "roof": { "overlay": "img/inspection-overlay/car/roof.png" },
                    "engine": { "overlay": "img/inspection-overlay/car/engine.png" },
                    "odometer":{ "overlay": "img/inspection-overlay/car/odometer.png" },
                    "dashcam": { "overlay": "img/inspection-overlay/car/dashcam.png" }
                }

                if(productType === "CarEV"){
                    vm.vehicleSide["charger"] = { "overlay": "img/inspection-overlay/car/charger.png"}
                    vm.vehicleSide["battery"] = { "overlay": "img/inspection-overlay/car/battery.png"}
                }

                let carAcsOverlayPhoto = {"overlay": "img/inspection-overlay/car/accessory.png"};
                for(let noOfPhoto = 4; noOfPhoto > 0 ; noOfPhoto--){
                    vm.vehicleAcsPhotos.push(carAcsOverlayPhoto);
                }
                
                let carDmgOverlayPhoto = {"overlay": "img/inspection-overlay/car/damage.png"};
                for(let noOfPhoto = 4; noOfPhoto > 0 ; noOfPhoto--){
                    vm.vehicleDmgPhotos.push(carDmgOverlayPhoto);
                }

            }else if(productType === "MotorBike"){
                vm.vehicleSide = {
                    "front": { "overlay": "img/inspection-overlay/bike/front.png"},
                    "back": { "overlay": "img/inspection-overlay/bike/back.png"},
                    "left": { "overlay": "img/inspection-overlay/bike/left.png"},
                    "right":{ "overlay": "img/inspection-overlay/bike/right.png"},
                    "engine":{ "overlay": "img/inspection-overlay/bike/engine.png"},
                    "odometer": { "overlay": "img/inspection-overlay/bike/odometer.png"}
                }
                
                vm.vehicleAcsPhotos = [
                    {"overlay": "img/inspection-overlay/bike/accessory1.png"},
                    {"overlay": "img/inspection-overlay/bike/accessory2.png"},
                    {"overlay": "img/inspection-overlay/bike/accessory3.png"},
                    {"overlay": "img/inspection-overlay/bike/accessory4.png"}
                ];

                let bikeDmgOverlayPhoto = {"overlay": "img/inspection-overlay/bike/damage.png"};
                for(let noOfPhoto = 4; noOfPhoto > 0 ; noOfPhoto--){
                    vm.vehicleDmgPhotos.push(bikeDmgOverlayPhoto);
                }
            }

            /* prepare 'defaultOverlay' for replace each photo block when user delete photo */
            vm.defaultOverlay["side"] = JSON.parse(JSON.stringify(vm.vehicleSide));
            vm.defaultOverlay["accessory"] = JSON.parse(JSON.stringify(vm.vehicleAcsPhotos));
            vm.defaultOverlay["damage"] = JSON.parse(JSON.stringify(vm.vehicleDmgPhotos));
        },
        getCssByKey:function(key){
            let vm = this;
            var css = null;

            if(vm.inspection.product_type__c === 'Car'|| vm.inspection.product_type__c === 'CarEV'){
                // Show 2 boxes if key is Vehicle Camera or Odometer reading
                if(key=='Vehicle Camera'||key=='Odometer reading'||key=='dashcam'||key=='odometer'||key=='charger'||key=='battery'){
                    css = 'col-12 col-md-6 col-lg-6'
                }else{
                    css = 'col-12 col-md-6 col-lg-4';
                }
            } else {
                css = 'col-12 col-md-6 col-lg-4';
            }

            return css;
        },
        initDropZone: function(){
            var vm = this;
            var uploadMaxFiles = 20;
            if(!vm.photoDropzone){
                vm.photoDropzone = new Dropzone("div#dropZoneForm", {
                    url: Global.uploadDocument.url + "/update/addFile/" + vm.sfid, // Set the url
                    maxFilesize: 20, // MB
                    maxFiles: uploadMaxFiles,
                    acceptedFiles: "image/jpeg, image/png, image/jpg, .heif, .heic", //".png, .jpg, .jpeg, .heif, .heic", //.JPEG , .PNG , HEIF (for iPhone) ,HEIC (for iPhone)
                    thumbnailWidth: 65,
                    thumbnailHeight: 65,
                    parallelUploads: 20,
                    autoQueue: true, // Make sure the files aren't queued until manually added
                    // clickable: ".fileinput-button", // Define the element that should be used as click trigger to select files.
                    renameFile: function (file) {
                        let newName = new Date().getTime() + "_" + file.name.replace(/\s+/g, '_');
                        return newName;
                    },
                    headers: {
                        Authorization: "Bearer " + vm.dmsToken
                    },
                });

                vm.photoDropzone.on("sending", function (file) {
                    vm.addQueue[file.upload.filename] =  { "tagKey" : vm.tempUploadTag }
                    vm.topLoading("hide");
                });

                vm.photoDropzone.on("success", async function (file, resp) {
                    if (resp.error != undefined && resp.error.length > 0){
                        console.log(resp.error);
                    } else {
                        if(resp.name){
                            let tagKey = vm.addQueue[resp.name]["tagKey"];
                            vm.uploadTag[resp.name] = ["Upload by Intermediary", vm.s3Tag[tagKey]];
                            if(await vm.tagPhoto(false)){
                                try {
                                    if(tagKey === "accessory"){
                                        let availableIdx = vm.vehicleAcsPhotos.findIndex(x => Object.prototype.hasOwnProperty.call(x, 'overlay'));
                                        if(availableIdx > -1){
                                            vm.vehicleAcsPhotos[availableIdx] = resp;
                                        }
                                    }else if(tagKey === "damage"){
                                        let availableIdx = vm.vehicleDmgPhotos.findIndex(x => Object.prototype.hasOwnProperty.call(x, 'overlay'));
                                        if(availableIdx > -1){
                                            vm.vehicleDmgPhotos[availableIdx] = resp;
                                        }
                                    }else{
                                        vm.vehicleSide[tagKey] = resp;
                                    }
                                    vm.hideImageBlockSpinner(tagKey);
                                    delete vm.addQueue[resp.name];
                                } catch (error) {
                                    console.error(error);
                                    vm.hideImageBlockSpinner(tagKey);
                                }
                            }else{
                                vm.hideImageBlockSpinner(tagKey);
                            }
                        }
                    }
                });

                vm.photoDropzone.on("error", function (file, errorMessage) {
                    console.error(errorMessage);
                    delete  vm.addQueue[file.upload.filename];
                    vm.topLoading("hide");
                    if(errorMessage.msg !== "access denied"){
                        vm.photoDropzone.removeFile(file);
                    }else{
                        vm.getDmsToken();
                    //     document.querySelector(".dz-error .error.text-danger").text(vm.$t("uploadError"));
                    }
                    vm.hideImageBlockSpinner(vm.tempUploadTag);
                    var modal = vm.modal("#modal-upload-insp-photo-error");
                    if( modal ){
                        modal.show();
                    }
                });

                vm.photoDropzone.on("queuecomplete", function () {
                    var uploadedPhotos = document.querySelectorAll(".dz-success").length;
                    var errorPhotos = document.querySelectorAll(".dz-error").length;
                    if ((uploadedPhotos === 0 || errorPhotos > 0) && Global.isRetrieve !== true) {
                        Global.uploadDocument.isError = true;
                    }else{
                        Global.uploadDocument.isError = false;
                    }
                    vm.photoDropzone.removeAllFiles(true);
                });
                const inputElement = document.getElementById("myFile");
                inputElement.removeEventListener("change", vm.handleFiles, false);
                inputElement.addEventListener("change", vm.handleFiles, false);
            }else{
                vm.photoDropzone.options.url = Global.uploadDocument.url + "/update/addFile/" + vm.sfid;
            }
        },
        hideImageBlockSpinner(tagKey){
            let vm = this;
            if(tagKey === "accessory"){
                vm.isShowAcsLoading = false;
            }else if(tagKey === "damage"){
                vm.isShowDmgLoading = false;
            }else{
                vm.vehicleSide[tagKey].showLoader = false;
            }
        },
        handleFiles: function(){
            var vm = this;
            vm.topLoading("show");
            if(vm.tempUploadTag === "accessory"){
                vm.isShowAcsLoading = true;
            }else if(vm.tempUploadTag === "damage"){
                vm.isShowDmgLoading = true;
            }else{
                vm.vehicleSide[vm.tempUploadTag].showLoader = true;
            }
            const inputElement = document.getElementById("myFile")

            if(inputElement.files.length > 0){
                Array.from(inputElement.files).forEach(file => {
                    vm.getDmsToken(function(){
                        vm.photoDropzone.addFile(file);
                    });
                });
            } else {
                vm.hideImageBlockSpinner(vm.tempUploadTag);
                vm.topLoading("hide");
            }
        },
        getDmsToken: async function(callback){
            var vm = this;
            try {
                await axios.post(Global.uploadDocument.url + "/authenticate", {
                    username: Global.uploadDocument.authenUsername,
                    password: Global.uploadDocument.authPassword
                },
                {
                    headers: {
                    "Content-Type": "application/json"}
                }).then((response) => {
                    vm.dmsToken = response.data.token;
                    vm.photoDropzone.options.headers = {
                        Authorization: "Bearer " + vm.dmsToken
                    };

                    if (callback && typeof(callback) === "function") {
                        callback();
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },
        tagPhoto: async function(isCreateTask){
            let vm = this;            
            let reqParam;
            if(isCreateTask){     
                /*
                    Re-tag any photo just to call tagging API
                    purpose to create SF inspection task
                    when user press the submit button
                */
                let theDummyTag = {};
                let theChosenToBeDummy = vm.vehicleSide['front'].name;
                theDummyTag[theChosenToBeDummy] = ["Upload by Intermediary"];
                reqParam = { 'prefix':  vm.sfid, 'tags': theDummyTag, 'isTaskCreation': isCreateTask }
                await vm.getDmsToken();    
            }else{
                reqParam = { 'prefix':  vm.sfid, 'tags': vm.uploadTag, 'isTaskCreation': isCreateTask };
            }
            let tagResult;
            await axios.post(Global.uploadDocument.url + "/update/bulkUpdateObjectTags", reqParam, {
                headers: {"Authorization": "Bearer " + vm.dmsToken}
            }).then(function(){
                vm.uploadTag = {};
                tagResult = true;
            }).catch(function (error) {
                console.error(error);
                tagResult = false;
            });
            return tagResult;
        },
        initMandatoryKey: function(){
            let vm = this;
            vm.mandatoryPhotoKey = ["front", "back", "left", "right", "engine", "odometer"];
            if(vm.inspection?.product_type__c === 'Car' || vm.inspection?.product_type__c === 'CarEV'){
                vm.mandatoryPhotoKey.push("roof");
                if(vm.inspection?.camera_installed){
                    vm.mandatoryPhotoKey.push("dashcam");
                }
                if(vm.inspection?.accessory_installed){
                    vm.mandatoryPhotoKey.push("accessory");
                }
            }
            if(vm.inspection?.product_type__c === 'CarEV'){
                if(vm.inspection?.wall_charger_coverage){
                    vm.mandatoryPhotoKey.push("charger");
                }
                if(vm.inspection?.battery_replacement){
                    vm.mandatoryPhotoKey.push("battery");
                }
            }
        }
    },
    mounted() {
        var vm = this;
        vm.initDropZone();
        vm.initMandatoryKey();
        vm.loadInspectionImages();
    },
    computed: {
        sfid: function(){
            return this.vid_sfid;
        }
    },
    watch: {
    },
    beforeUnmount(){
        // document.querySelector("#previews-new").innerHTML = "";
    }
}
</script>

<i18n>
{
  "en": {
    "validateMessage": "Only jpeg, png, heif or heic (for iPhone), 20 MB max size for each",
    "upload": "Upload",
    "uploadError": "Upload error",
    "confirmDelete": "Delete this picture?",
    "delete": "Delete",
    "cancel": "Cancel",
    "close": "Close",
    "warning": "Warning",
    "submit": "Submit",
    "doNotSubmit": "Don't Submit",
    "tagName":{
        "front": "Vehicle Front",
        "back": "Vehicle Back",
        "left": "Vehicle Left Side",
        "right": "Vehicle Right Side",
        "roof": "Vehicle Roof",
        "engine": "Vehicle Engine",
        "odometer": "Odometer Reading",
        "dashcam": "Vehicle Camera",
        "vehicleAccessory" : "Vehicle Accessory",
        "vehicleDamage" : "Vehicle Damage",
        "charger" : "Home wall charger ID",
        "battery" : "Battery ID"
    },
    "mandatoryErrMsg":"Please upload all the required ( * ) photos to complete the inspection process and confirm the policy coverage",
    "unsavedWrnMsg": "Unsaved data will be lost",
    "submitFailed": "Upload Failed",
    "submitSuccess": "Upload Successful"
  },
  "th": {
    "validateMessage": "เฉพาะไฟล์ jpeg, png, heif หรือ heic (สำหรับ iPhone), ขนาดไม่เกิน 20 MB",
    "upload": "อัปโหลด",
    "uploadError": "อัปโหลด ไม่สำเร็จ",
    "confirmDelete": "ลบภาพนี้?",
    "delete": "ลบ",
    "cancel": "ยกเลิก",
    "close": "ปิด",
    "warning": "แจ้งเตือน",
    "submit": "บันทึก",
    "doNotSubmit": "ไม่บันทึก",
    "tagName":{
        "front": "ด้านหน้า",
        "back": "ด้านหลัง",
        "left": "ด้านซ้าย",
        "right": "ด้านขวา",
        "roof": "หลังคารถ",
        "engine": "เครื่องยนต์",
        "odometer": "มาตรวัดระยะทาง",
        "dashcam": "กล้องติดรถยนต์",
        "vehicleAccessory" : "อุปกรณ์เสริมอื่น ๆ",
        "vehicleDamage" : "บริเวณที่ได้รับความเสียหาย (ถ้ามี)",
        "charger" : "Home wall charger ID",
        "battery" : "Battery ID"
    },
    "mandatoryErrMsg":"กรุณาอัปโหลดภาพตามที่กำหนด ( * ) เพื่อความสมบูรณ์ของขั้นตอนการตรวจสภาพและยืนยันความคุ้มครองในกรมธรรม์",
    "unsavedWrnMsg": "ข้อมูลที่ยังไม่ถูกบันทึกจะสูญหาย",
    "submitFailed": "อัพโหลดไม่สำเร็จ",
    "submitSuccess": "อัพโหลดสำเร็จ"
  },
  "zh": {
    "validateMessage": "仅限jpeg、png、heif或heic(适用于iPhone)，每张照片最大尺寸为20MB",
    "upload": "上传",
    "uploadError": "上传错误",
    "confirmDelete": "Delete this picture?",
    "delete": "Delete",
    "cancel": "Cancel",
    "close": "Close",
    "warning": "Warning",
    "submit": "Submit",
    "doNotSubmit": "Don't Submit",
    "tagName":{
        "front": "Vehicle Front",
        "back": "Vehicle Back",
        "left": "Vehicle Left Side",
        "right": "Vehicle Right Side",
        "roof": "Vehicle Roof",
        "engine": "Vehicle Engine",
        "odometer": "Odometer Reading",
        "dashcam": "Vehicle Camera",
        "vehicleAccessory" : "Vehicle Accessory",
        "vehicleDamage" : "Vehicle Damage",
        "charger" : "Home wall charger ID",
        "battery" : "Battery ID"
    },
    "mandatoryErrMsg":"Please upload all the required ( * ) photos to complete the inspection process and confirm the policy coverage",
    "unsavedWrnMsg": "Unsaved data will be lost",
    "submitFailed": "Upload Failed",
    "submitSuccess": "Upload Successful"
  }
}
</i18n>
