export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "detailHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opportunity Detail"])},
        "oppNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opportunity Number"])},
        "productOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Option"])},
        "excess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excess"])},
        "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garage"])},
        "driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver"])},
        "compulsory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compulsory"])},
        "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created Date"])},
        "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policy Start Date"])},
        "brokerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broker Name"])},
        "vehicleInspection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Inspection"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
          "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Required"])},
          "vdoInspection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please inform customer to call 02 582 8800 to do video inspection"])}
        },
        "agentEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent email"])},
        "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
        "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand"])},
        "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
        "subModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Model"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years of manufacture"])},
        "carPlate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car Plate"])},
        "custInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Information"])},
        "custName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "custMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
        "custEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
        "retrieveQuoteLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrieve Quote Link"])},
        "click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click"])},
        "mrKumka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr.Kumka"])},
        "businessValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business validate"])},
        "notPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Pass"])},
        "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium"])},
        "activatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated date"])},
        "stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage"])},
        "inspectionPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspection Photo Upload"])},
        "displayVehicleInspecitonPhotos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Inspection Photos"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
        "inspectionStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspection Status"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type "])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])}
      },
      "th": {
        "detailHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดใบเสนอราคา"])},
        "oppNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเลขใบเสนอราคา"])},
        "productOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวเลือกผลิตภัณฑ์"])},
        "excess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าเสียหายส่วนแรก"])},
        "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อู่"])},
        "driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ขับขี่"])},
        "compulsory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พ.ร.บ"])},
        "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่สร้าง"])},
        "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันเริ่มกรมธรรม์"])},
        "brokerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โบรกเกอร์"])},
        "vehicleInspection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสภาพรถ"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ต้องตรวจสภาพรถ"])},
          "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ต้องตรวจสภาพรถ"])},
          "vdoInspection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาแจ้งลูกค้าโทร 02 582 8800 เพื่อตรวจสภาพรถผ่านวิดีโอคอล ขอบคุณค่ะ"])}
        },
        "agentEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมล์ที่ปรึกษา"])},
        "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลิตภัณฑ์"])},
        "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยี่ห้อ"])},
        "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รุ่น"])},
        "subModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดรุ่น"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปีที่ผลิต"])},
        "carPlate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ป้ายทะเบียน"])},
        "custInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดข้อมูลลูกค้า"])},
        "custName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อลูกค้า"])},
        "custMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบอร์โทรศัพท์"])},
        "custEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมล์"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลิงก์"])},
        "retrieveQuoteLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูใบเสนอราคา"])},
        "click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คลิก"])},
        "mrKumka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr.Kumka"])},
        "businessValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การรับประกัน"])},
        "notPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ผ่าน"])},
        "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบี้ยประกันภัย"])},
        "activatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ปิดการขาย"])},
        "stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะใบเสนอ"])},
        "inspectionPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัพโหลดรูปตรวจสภาพรถ"])},
        "displayVehicleInspecitonPhotos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Inspection Photos"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึก"])},
        "inspectionStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะการตรวจสภาพรถ"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภท "])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความคิดเห็น"])}
      },
      "zh": {
        "detailHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["机会详情"])},
        "oppNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["机会号码"])},
        "productOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Option"])},
        "excess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超额部分"])},
        "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["维修店铺"])},
        "driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["司机"])},
        "compulsory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["强制性的"])},
        "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建日期"])},
        "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始日期"])},
        "brokerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经纪人姓名"])},
        "vehicleInspection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车辆检验"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要"])},
          "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不需要"])},
          "vdoInspection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please inform customer to call 02 582 8800 to do video inspection"])}
        },
        "agentEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理邮箱"])},
        "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品"])},
        "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌"])},
        "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["型号"])},
        "subModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子型号"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生产年份"])},
        "carPlate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车牌"])},
        "custInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户资料"])},
        "custName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
        "custMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户手机号码"])},
        "custEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户邮箱"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接"])},
        "retrieveQuoteLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["检索报价链接"])},
        "click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击"])},
        "mrKumka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr.Kumka"])},
        "businessValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商业验证"])},
        "notPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不通过"])},
        "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保费"])},
        "activatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated date"])},
        "stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage"])},
        "inspectionPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspection Photo Upload"])},
        "displayVehicleInspecitonPhotos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Inspection Photos"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
        "inspectionStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspection Status"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type "])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])}
      }
    }
  })
}
