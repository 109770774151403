export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "validateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only jpeg, png, heif or heic (for iPhone), 20 MB max size for each"])},
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
        "uploadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload error"])},
        "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this picture?"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
        "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
        "doNotSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't Submit"])},
        "tagName": {
          "front": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Front"])},
          "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Back"])},
          "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Left Side"])},
          "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Right Side"])},
          "roof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Roof"])},
          "engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Engine"])},
          "odometer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odometer Reading"])},
          "dashcam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Camera"])},
          "vehicleAccessory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Accessory"])},
          "vehicleDamage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Damage"])},
          "charger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home wall charger ID"])},
          "battery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Battery ID"])}
        },
        "mandatoryErrMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload all the required ( * ) photos to complete the inspection process and confirm the policy coverage"])},
        "unsavedWrnMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsaved data will be lost"])},
        "submitFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Failed"])},
        "submitSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Successful"])}
      },
      "th": {
        "validateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เฉพาะไฟล์ jpeg, png, heif หรือ heic (สำหรับ iPhone), ขนาดไม่เกิน 20 MB"])},
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปโหลด"])},
        "uploadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปโหลด ไม่สำเร็จ"])},
        "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบภาพนี้?"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบ"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิก"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิด"])},
        "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แจ้งเตือน"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึก"])},
        "doNotSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่บันทึก"])},
        "tagName": {
          "front": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ด้านหน้า"])},
          "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ด้านหลัง"])},
          "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ด้านซ้าย"])},
          "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ด้านขวา"])},
          "roof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หลังคารถ"])},
          "engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เครื่องยนต์"])},
          "odometer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มาตรวัดระยะทาง"])},
          "dashcam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กล้องติดรถยนต์"])},
          "vehicleAccessory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อุปกรณ์เสริมอื่น ๆ"])},
          "vehicleDamage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บริเวณที่ได้รับความเสียหาย (ถ้ามี)"])},
          "charger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home wall charger ID"])},
          "battery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Battery ID"])}
        },
        "mandatoryErrMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาอัปโหลดภาพตามที่กำหนด ( * ) เพื่อความสมบูรณ์ของขั้นตอนการตรวจสภาพและยืนยันความคุ้มครองในกรมธรรม์"])},
        "unsavedWrnMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลที่ยังไม่ถูกบันทึกจะสูญหาย"])},
        "submitFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัพโหลดไม่สำเร็จ"])},
        "submitSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัพโหลดสำเร็จ"])}
      },
      "zh": {
        "validateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅限jpeg、png、heif或heic(适用于iPhone)，每张照片最大尺寸为20MB"])},
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传"])},
        "uploadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传错误"])},
        "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this picture?"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
        "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
        "doNotSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't Submit"])},
        "tagName": {
          "front": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Front"])},
          "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Back"])},
          "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Left Side"])},
          "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Right Side"])},
          "roof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Roof"])},
          "engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Engine"])},
          "odometer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odometer Reading"])},
          "dashcam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Camera"])},
          "vehicleAccessory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Accessory"])},
          "vehicleDamage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Damage"])},
          "charger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home wall charger ID"])},
          "battery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Battery ID"])}
        },
        "mandatoryErrMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload all the required ( * ) photos to complete the inspection process and confirm the policy coverage"])},
        "unsavedWrnMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsaved data will be lost"])},
        "submitFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Failed"])},
        "submitSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Successful"])}
      }
    }
  })
}
