<style>
</style>

<template>
  <div id="inspection-report" style="padding: 0 1.5rem;">
    <div class="pb-1 mb-0 mb-lg-1">
      <SearchForm @search="search" :reportName="reportName" :isProcessing="isProcessing"></SearchForm>
      <ReportTable
        :inspections="inspections"
        :totalRows="totalRows"
        :reportName="reportName"
        :isProcessing="isProcessing"
        @search="search"
        @get-details="getDetails"
      ></ReportTable>
    </div>
    <div
      id="inspection_detail_modal"
      data-bs-backdrop="static"
      tabindex="-1"
      role="dialog"
      aria-labelledby="inspection_detail_modal"
      class="modal fade"
      aria-modal="true"
      style="display: none"
    >
      <div role="document" class="modal-dialog modal-lg modal2detail4pk">
        <div class="modal-content">
          <div class="modal-header">
            <h4 id="inspection_detail_modal_label" class="modal-title">
              {{$t("detailHeader")}}
            </h4>
            <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
            <button type="button" class="btn-close" aria-label="Close" @click="close()"></button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
                <dl class="row mt-2 py-2 bg-white rounded shadow px-2">
                    <div class="col-7">
                    <div class="row">
                        <dt class="col-sm-6">{{$t("oppNumber")}}</dt>
                        <dd class="col-sm-6">{{inspection?.oppname}}</dd>
                    </div>
                    <div class="row">
                        <dt class="col-sm-6">{{$t("productOption")}}</dt>
                        <dd class="col-sm-6">{{showNullWithDash(inspection?.plan_type ? $t("type") + inspection?.plan_type : "")}}</dd>
                    </div>
                    <div class="row">
                        <dt class="col-sm-6">{{$t("excess")}}</dt>
                        <dd class="col-sm-6">{{showNullWithDash(inspection?.excess__c)}}</dd>
                    </div>
                    <div class="row">
                        <dt class="col-sm-6">{{$t("garage")}}</dt>
                        <dd class="col-sm-6">{{showNullWithDash(inspection?.workshop_type__c)}}</dd>
                    </div>
                    <div class="row">
                        <dt class="col-sm-6">{{$t("driver")}}</dt>
                        <dd class="col-sm-6">{{showNullWithDash(inspection?.driver_plan__c)}}</dd>
                    </div>
                    <div class="row">
                        <dt class="col-sm-6">{{$t("compulsory")}}</dt>
                        <dd class="col-sm-6">{{inspection?.compulsory_plan__c}}</dd>
                    </div>
                    <div class="row">
                        <dt class="col-sm-6">{{$t("createdDate")}}</dt>
                        <dd class="col-sm-6">{{inspection?.createddate}}</dd>
                    </div>
                    <div class="row">
                        <dt class="col-sm-6">{{$t("startDate")}}</dt>
                        <dd class="col-sm-6">{{showNullWithDash(inspection?.start_date__c)}}</dd>
                    </div>
                    <div class="row">
                        <dt class="col-sm-6">{{$t("activatedDate")}}</dt>
                        <dd class="col-sm-6">{{showNullWithDash(inspection?.transacted_date__c)}}</dd>
                    </div>
                    <div class="row">
                        <dt class="col-sm-6">{{ $t("inspectionStatus") }}</dt>
                        <dd class="col-sm-6" :class="{'text-success' : inspection?.inspection_status === 'Closed', 'text-orange' : inspection?.inspection_status === 'Cancelled', 'text-info' : inspection?.inspection_status === 'Open'}">
                          {{inspection?.inspection_status === "Closed" ? "Approved" : inspection?.inspection_status === "In Progress" ? "Pending" : inspection?.inspection_status}}
                        </dd>
                    </div>
                    <div class="row" v-if="inspection?.inspection_status === 'Open' && inspection?.inspection_type__c !== 'VDO Inspection'">
                        <dt class="col-sm-6">{{$t("vehicleInspection.label")}}</dt>
                        <dd class="col-sm-6" style="color: #ff5011">{{$t("vehicleInspection.required")}}</dd>
                    </div>
                    <div class="row" v-if="Global.authority === 'ADMIN'">
                        <dt class="col-sm-6">{{$t("brokerName")}}</dt>
                        <dd class="col-sm-6">{{inspection?.broker_name}}</dd>
                    </div>
                    <div class="row" v-if="Global.authority !== 'AGENT_USER'">
                        <dt class="col-sm-6">{{$t("agentEmail")}}</dt>
                        <dd class="col-sm-6">{{inspection?.intermediary_rept_email}}</dd>
                    </div>
                    </div>
                    <div class="col-5">
                    <div class="row">
                        <dt class="col-sm-6">{{$t("product")}}</dt>
                        <dd class="col-sm-6">{{displaySpecifiedProductType(inspection?.product_type__c)}}</dd>
                    </div>
                    <div class="row">
                        <dt class="col-sm-6">{{$t("brand")}}</dt>
                        <dd class="col-sm-6">{{showNullWithDash(inspection?.make__c)}}</dd>
                    </div>
                    <div class="row">
                        <dt class="col-sm-6">{{$t("model")}}</dt>
                        <dd class="col-sm-6">{{showNullWithDash(inspection?.model_family__c)}}</dd>
                    </div>
                    <div class="row">
                        <dt class="col-sm-6">{{$t("subModel")}}</dt>
                        <dd class="col-sm-6">-</dd>
                    </div>
                    <div class="row">
                        <dt class="col-sm-6">{{$t("year")}}</dt>
                        <dd class="col-sm-6">{{showNullWithDash(inspection?.year_of_manufacture__c)}}</dd>
                    </div>
                    <div class="row">
                        <dt class="col-sm-6">{{$t("carPlate")}}</dt>
                        <dd class="col-sm-6">{{showNullWithDash(inspection?.car_plate_number__c)}}</dd>
                    </div>
                    </div>
                    <div class="col-12">
                      <div class="row" v-if="inspection?.inspection_status === 'Open' && inspection?.inspection_type__c === 'VDO Inspection'">
                        <dt :class="Global.locale === 'th' ? 'col-sm-2' : 'col-sm-3'">{{$t("vehicleInspection.label")}}</dt>
                          <dd :class="Global.locale === 'th' ? 'col-sm-10' : 'col-sm-9'" style="color: #ff5011">{{$t("vehicleInspection.vdoInspection")}}</dd>
                      </div>
                    </div>
                </dl>
                <dl class="row mt-2 py-2 bg-white rounded shadow px-2">  
                    <dt class="col-sm-12"><h6>{{$t("custInfo")}}</h6></dt>
                    <dd></dd>
                    <div class="col-7">
                        <div class="row">
                        <dt class="col-sm-6">{{$t("custName")}}</dt>
                        <dd class="col-sm-6">{{showNullWithDash(inspection?.name)}}</dd>
                        </div>
                        <div class="row">
                        <dt class="col-sm-6">{{$t("custMobile")}}</dt>
                        <dd class="col-sm-6">{{showNullWithDash(inspection?.mobile)}}</dd>
                        </div>
                        <div class="row">
                        <dt class="col-sm-6">{{$t("custEmail")}}</dt>
                        <dd class="col-sm-6">{{showNullWithDash(inspection?.contact_email_address__c)}}</dd>
                        </div>
                    </div>
                    <div class="col-5">
                    </div>
                </dl>
                <!-- <dl class="row mt-2 py-2 bg-white rounded shadow px-2">
                    <dt class="col-sm-12"><h6>{{$t("link")}}</h6></dt>
                    <dd></dd>
                    <div class="col-7">
                    <div class="row">
                        <dt class="col-sm-6">{{$t("retrieveQuoteLink")}}</dt>
                        <dd class="col-sm-6">
                        <a :href="Global.retrieveQuoteURL" target="_blank" :id="'link-retrieve-' + inspection?.oppname">{{$t("click")}}</a>
                        </dd>
                    </div>
                    <div class="row">
                        <dt class="col-sm-6">{{$t("mrKumka")}}</dt>
                        <dd class="col-sm-6">-</dd>
                    </div>
                    </div>
                    <div class="col-5">
                    </div>
                </dl> -->
                <dl class="row mt-2 py-2 bg-white rounded shadow px-2">
                  <dt class="col-sm-12"><h6>{{$t("comment")}}</h6></dt>
                  <dd></dd>
                  <div class="col-12">
                    <div class="row">
                      <dd class="col-sm-12">
                        {{ showNullWithDash(inspection?.rejectComment) }}
                      </dd>
                    </div>
                  </div>
                </dl>
                <dl class="row mt-2 py-2 bg-white rounded shadow px-2" v-if="(['MotorBike'].indexOf(inspection?.product_type__c) > -1 || (['Car', 'CarEV'].indexOf(inspection?.product_type__c) > -1 && inspection.plan_type !== '1')) && (inspection.inspection_status === 'Open' || inspection.inspection_status === 'In Progress')">
                  <dt class="col-sm-12"><h6>{{$t("inspectionPhoto")}}</h6></dt>
                  <dd></dd>
                  <div class="col-12">                    
                    <UploadInspectionPhoto v-if="inspection.vid_sfid && (inspection.inspection_status === 'Open' || inspection.inspection_status === 'In Progress')" :oppName="inspection.oppname" :vid_sfid="inspection.vid_sfid" :inspectionStatus="inspection.inspection_status" :inspection="inspection" ref="uploadDoc"></UploadInspectionPhoto>
                  </div> 
                </dl>
                <dl class="row mt-2 py-2 bg-white rounded shadow px-2" v-if="inspection?.vid_sfid && !['Closed', 'Cancelled'].includes(inspection.inspection_status) && ( [ 'MotorBike'].indexOf(inspection?.product_type__c) > -1 ||( (['Car', 'CarEV'].indexOf(inspection?.product_type__c) > -1 && inspection.plan_type !== '1')))">
                  <dt class="col-sm-12"><h6>{{$t("displayVehicleInspecitonPhotos")}}</h6></dt>
                  <dd></dd>
                  <div class="col-12">
                    <DisplayUploadedDocument :oppName="inspection.oppname" :oppId="inspection.oppid" :inspectionStatus="inspection.inspection_status" :sfId="inspection.vid_sfid"></DisplayUploadedDocument>
                  </div>
                </dl>
            </div>
          </div>

          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-primary text-white bg-gradient-info" @click="submitInspectionPhoto()" v-if="isShowSubmitInspectionPhotoButton()"> -->
            <button type="button" class="btn btn-primary text-white bg-gradient-info" @click="$refs.uploadDoc.submitWithoutClose()" v-if="isShowSubmitInspectionPhotoButton()">
              {{$t('submit')}}
            </button>
            <button type="button" class="btn btn-primary text-white bg-gradient-primary" @click="close()">
              {{$t('menu.close')}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="teleport-target"></div>
    <div id="preview-inspection-photo-teleport-target"></div>
    <div id="confirm-delete-teleport-target"></div>
    <div id="error-upload-inspection-photo-teleport-target"></div>
    <div id="error-required-mandatory-photo-teleport-target"></div>
    <div id="submit-result-teleport-target"></div>
  </div>
</template>

<script>
import Global from "@/stores/GlobalVariables.js";
import mixin from "@/components/Mixin";
import SearchForm from "@/components/SearchForm";
import ReportTable from "@/components/ReportTable";
import UploadInspectionPhoto from "@/components/UploadInspectionPhoto";
import DisplayUploadedDocument from "@/components/DisplayUploadedDocument";
import axios from "axios";

export default {
  mixins: [mixin],
  name: "InspecitonReport",
  components: { SearchForm, ReportTable, UploadInspectionPhoto, DisplayUploadedDocument },
  data: function () {
    return {
      Global,
      inspections: [],
      totalRows: 0,
      inspection: null,
      isProcessing: false,
      reportName: "inspectionReport"
    };
  },
  created() {
    if(!this.isNullOrEmpty(this.$route.query.start) && !this.isNullOrEmpty(this.$route.query.end) && !this.isNullOrEmpty(this.$route.query.dateType) && !this.isNullOrEmpty(this.$route.query.inspectionStatus)){
      Global.searchConditions[this.reportName].dateFrom = this.$route.query.start;
      Global.searchConditions[this.reportName].dateTo = this.$route.query.end;
      Global.searchConditions[this.reportName].dateType = this.$route.query.dateType;
      Global.searchConditions[this.reportName].inspectionStatus = this.$route.query.inspectionStatus;
      Global.searchConditions[this.reportName].productType = '';
    }else{
      Global.searchConditions[this.reportName].dateFrom = "";
      Global.searchConditions[this.reportName].dateTo = "";
      Global.searchConditions[this.reportName].productType = "";
      Global.searchConditions[this.reportName].inspectionStatus = "";
      Global.searchConditions[this.reportName].dateType = "inspectiontaskcreateddate";
      Global.searchConditions[this.reportName].sortKey = "inspectiontaskcreateddate";
      Global.searchConditions[this.reportName].sortDirection = "desc";
      Global.searchConditions[this.reportName].oppNumber = "";
      Global.searchConditions[this.reportName].custName = "";
      Global.searchConditions[this.reportName].phoneNumber = "";
      Global.searchConditions[this.reportName].custEmail = "";
      Global.searchConditions[this.reportName].carPlate = "";
      Global.searchConditions[this.reportName].agentEmail = "";
      Global.searchConditions[this.reportName].currentPage = 1;
      Global.searchConditions[this.reportName].rowsPerPage = 20;
      Global.searchConditions[this.reportName].isAdvanceSearch = false;
    }
    this.eventTracking('mainMenu', 'Menu_InspectionReport', 'Portal');
  },
  mounted() {
    var vm = this;
    this.hide2menu();
    this.search();
    var myModalEl = document.getElementById('inspection_detail_modal')
    if(myModalEl){
      myModalEl.addEventListener('hide.bs.modal', function () {
        vm.inspection = null;
      })
    }
  },
  methods: {
    close: function(){
      let vm = this;
      var detailModal = vm.modal("#inspection_detail_modal");
      if(vm.inspection.inspection_status === "In Progress" && vm.$refs.uploadDoc.isMandatoryPhotoRequired()){
        var warningModalElm = document.getElementById('modal-required-mandatory-photo-error');
        var warningModal = vm.modal("#modal-required-mandatory-photo-error");
        
        if(warningModal){
          warningModal.show();
        }
        
        if(warningModalElm){
          warningModalElm.addEventListener('hide.bs.modal', function () {
            detailModal.hide()
          })
        }
      } else {
        if(detailModal){
          detailModal.hide();
        }
      }
    },
    isShowSubmitInspectionPhotoButton: function(){
      let vm = this;
      let product = vm.inspection?.product_type__c;
      let planType = vm.inspection?.plan_type;
      let status = vm.inspection?.inspection_status;
      if(product === 'MotorBike' && (status === 'Open' || status === 'In Progress')){
        return true;
      } else if ((product === 'Car' || product === 'CarEV') && planType !== "1" && (status === 'Open' || status === 'In Progress')){
        return true;
      }else {
        return false;
      }
      
    },
    // submitInspectionPhoto: async function(){
    //   let vm = this;
    //   if(await vm.$refs.uploadDoc.submitPhotoThenClose()){
    //     this.topLoading("show");
    //     setTimeout(()=>{
    //         vm.search();
    //         this.topLoading("hide");
    //     }, 2500); 
    //   } 
    // },
    async search() {
      this.isProcessing = true;
      try {
        var response = await axios.get(Global.apiUrl + "/inspectionReport", {
          params: Global.searchConditions[this.reportName]
        });
        this.inspections = response.data.inspection;
        this.totalRows = parseInt(response.data.count);
        this.isProcessing = false;
      } catch (error) {
        if (error?.response?.status === 401) {
          this.isProcessing = false;
          this.$router.go();
        }
        console.error(error);
      }
    },
    async getDetails(inspection){
      this.topLoading("show");
      this.inspection = Object.assign({}, inspection);
      try {
        var response = await axios.get(Global.apiUrl + "/inspectionReport/details/" + inspection.oppname);
        if(response){
          var details = response.data.details;
          this.inspection.name = details?.name;
          this.inspection.mobile = details?.mobile;
          this.inspection.contact_email_address__c = details?.contact_email_address__c;
          this.inspection.excess__c = details?.excess__c;
          this.inspection.workshop_type__c = details?.workshop_type__c;
          this.inspection.driver_plan__c = details?.driver_plan__c;
          this.inspection.compulsory_plan__c = details?.compulsory_plan__c;
          this.inspection.make__c = details?.make__c;
          this.inspection.model_family__c = details?.model_family__c;
          this.inspection.year_of_manufacture__c = details?.year_of_manufacture__c;
          this.inspection.car_plate_number__c = details?.car_plate_number__c;
        }

        try {
          var commentResponse = await axios.get(Global.apiUrl + "/inspectionReport/details/approvalhistory/" + inspection.vid_sfid);
          if(commentResponse && commentResponse?.data?.success){
            if(commentResponse.data.dataResponse?.StepStatus === 'Rejected'){
              this.inspection.rejectComment = commentResponse.data.dataResponse.Comments;
            }
          }
        } catch (commentErr){
          console.error(commentErr);
        }

        this.topLoading("hide");
        var modal = this.modal("#inspection_detail_modal");
        if( modal ){
          modal.show();
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          this.topLoading("hide");
          this.$router.go();
        }
        console.error(error);
      }
    }
  },
};
</script>
<i18n>
{
  "en": {
    "detailHeader": "Opportunity Detail",
    "oppNumber": "Opportunity Number",
    "productOption": "Product Option",
    "excess": "Excess",
    "garage": "Garage",
    "driver": "Driver",
    "compulsory": "Compulsory",
    "createdDate": "Created Date",
    "startDate": "Policy Start Date",
    "brokerName": "Broker Name",
    "vehicleInspection": {
      "label": "Vehicle Inspection",
      "required": "Required",
      "notRequired": "Not Required",
      "vdoInspection": "Please inform customer to call 02 582 8800 to do video inspection"
    },
    "agentEmail": "Agent email",
    "product": "Product",
    "brand": "Brand",
    "model": "Model",
    "subModel": "Sub Model",
    "year": "Years of manufacture",
    "carPlate": "Car Plate",
    "custInfo": "Customer Information",
    "custName": "Name",
    "custMobile": "Mobile Number",
    "custEmail": "Email",
    "link": "Link",
    "retrieveQuoteLink": "Retrieve Quote Link",
    "click": "Click",
    "mrKumka": "Mr.Kumka",
    "businessValidate": "Business validate",
    "notPass": "Not Pass",
    "premium": "Premium",
    "activatedDate": "Activated date",
    "stage": "Stage",
    "inspectionPhoto": "Inspection Photo Upload",
    "displayVehicleInspecitonPhotos": "Vehicle Inspection Photos",
    "submit": "Submit",
    "inspectionStatus": "Inspection Status",
    "type": "Type ",
    "comment": "Comment"
  },
  "th": {
    "detailHeader": "รายละเอียดใบเสนอราคา",
    "oppNumber": "หมายเลขใบเสนอราคา",
    "productOption": "ตัวเลือกผลิตภัณฑ์",
    "excess": "ค่าเสียหายส่วนแรก",
    "garage": "อู่",
    "driver": "ผู้ขับขี่",
    "compulsory": "พ.ร.บ",
    "createdDate": "วันที่สร้าง",
    "startDate": "วันเริ่มกรมธรรม์",
    "brokerName": "โบรกเกอร์",
    "vehicleInspection": {
      "label": "ตรวจสภาพรถ",
      "required": "ต้องตรวจสภาพรถ",
      "notRequired": "ไม่ต้องตรวจสภาพรถ",
      "vdoInspection": "กรุณาแจ้งลูกค้าโทร 02 582 8800 เพื่อตรวจสภาพรถผ่านวิดีโอคอล ขอบคุณค่ะ"
    },
    "agentEmail": "อีเมล์ที่ปรึกษา",
    "product": "ผลิตภัณฑ์",
    "brand": "ยี่ห้อ",
    "model": "รุ่น",
    "subModel": "รายละเอียดรุ่น",
    "year": "ปีที่ผลิต",
    "carPlate": "ป้ายทะเบียน",
    "custInfo": "รายละเอียดข้อมูลลูกค้า",
    "custName": "ชื่อลูกค้า",
    "custMobile": "เบอร์โทรศัพท์",
    "custEmail": "อีเมล์",
    "link": "ลิงก์",
    "retrieveQuoteLink": "ดูใบเสนอราคา",
    "click": "คลิก",
    "mrKumka": "Mr.Kumka",
    "businessValidate": "การรับประกัน",
    "notPass": "ไม่ผ่าน",
    "premium": "เบี้ยประกันภัย",
    "activatedDate": "วันที่ปิดการขาย",
    "stage": "สถานะใบเสนอ",
    "inspectionPhoto": "อัพโหลดรูปตรวจสภาพรถ",
    "displayVehicleInspecitonPhotos": "Vehicle Inspection Photos",
    "submit": "บันทึก",
    "inspectionStatus": "สถานะการตรวจสภาพรถ",
    "type": "ประเภท ",
    "comment": "ความคิดเห็น"
  },
  "zh": {
    "detailHeader": "机会详情",
    "oppNumber": "机会号码",
    "productOption": "Product Option",
    "excess": "超额部分",
    "garage": "维修店铺",
    "driver": "司机",
    "compulsory": "强制性的",
    "createdDate": "创建日期",
    "startDate": "开始日期",
    "brokerName": "经纪人姓名",
    "vehicleInspection": {
      "label": "车辆检验",
      "required": "需要",
      "notRequired": "不需要",
      "vdoInspection": "Please inform customer to call 02 582 8800 to do video inspection"
    },
    "agentEmail": "代理邮箱",
    "product": "产品",
    "brand": "品牌",
    "model": "型号",
    "subModel": "子型号",
    "year": "生产年份",
    "carPlate": "车牌",
    "custInfo": "客户资料",
    "custName": "名称",
    "custMobile": "客户手机号码",
    "custEmail": "客户邮箱",
    "link": "链接",
    "retrieveQuoteLink": "检索报价链接",
    "click": "点击",
    "mrKumka": "Mr.Kumka",
    "businessValidate": "商业验证",
    "notPass": "不通过",
    "premium": "保费",
    "activatedDate": "Activated date",
    "stage": "Stage",
    "inspectionPhoto": "Inspection Photo Upload",
    "displayVehicleInspecitonPhotos": "Vehicle Inspection Photos",
    "submit": "Submit",
    "inspectionStatus": "Inspection Status",
    "type": "Type ",
    "comment": "Comment"
  }
}
</i18n>