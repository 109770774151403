
<style>
#display-car-inspection-photos .divForImg {
    padding: 4px !important;
}

#display-car-inspection-photos .divForImg > .img-wrapper {
    position: relative;
    max-height: 175px;
    overflow: hidden;
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    padding: 3px;
    display: inline-block;
    max-width: 100%;
    cursor: pointer;
}
#display-car-inspection-photos .divForImg > .img-wrapper > img {
    max-height: 168px;
    max-width: 100%;
    height: 168px;
}

#display-car-inspection-photos .img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
}

#display-car-inspection-photos .previewModal {
    z-index: 2000 !important;
}

#previewModal #btnDel {
    right: 0px;
    top: 0px;
}

#previewModal #delOnPreview {
    width: 20px;
    height: 20px;
}
</style>

<template>
<div id="display-car-inspection-photos">
    <div v-if="onloadImage" class="row px-2" >
        <div class="divForImg col-sm-12 col-md-6 mb-3"></div>
        <div class="divForImg col-sm-12 col-md-6 mb-3"><span v-if="onloadImage" class="spinner-border spinner-border-lg loading text-info m-12" role="status" aria-hidden="true"></span>  </div>    
    </div>      
    <div class="row px-3" v-else-if="!onloadImage && ArrayImage.length <= 0" style="color: #ff5011">
        {{$t('inspectionPhotoNotfound')}}
    </div>
    <div class="row px-3" v-else-if="!onloadImage && ArrayImage.length > 0" style="color: #ff5011">
        <div v-for="img in pageOfItems" :key="img.s3key" class="divForImg col-sm-12 col-md-4 mb-3">
            <div class="img-wrapper">
                <img v-if="img.docType != 'pdf' && img.docType != 'mp4'" :src="img.thumbnailUrl" alt="..." @click="openPreviewModal(img)">
                <video class="img-thumbnail" v-if="img.docType == 'mp4'"  @click="openPreviewModal(img)">
                    <source :src="img.videoUrl">
                </video>
                <div class="img-thumbnail" v-if="img.docType == 'pdf'" @click="openPreviewModal(img)">
                    <i data-feather="file-text" />
                    {{ img.name.split("_")[1] }}
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 mb-4 text-center">
        <pagination
            v-model="currentPage"
            :records="ArrayImage.length"
            :per-page="itemPerPage"
            @paginate="getData()"
            :options="paginateOptions"
        />
    </div>
</div>

<Teleport to="#preview-inspection-photo-teleport-target">
    <div id="previewModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="previewModalTitle" aria-hidden="true" style="background-color: #000000; display: none;">
        <div class="modal-dialog modal-lg modal-dialog-centered modal2detail4pk" role="document" id="dialogPreview">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="embed-responsive embed-responsive-16by9" v-if="showItem.docType == 'pdf'">
                        <iframe class="embed-responsive-item" :src="showItem.pdfUrl" allowfullscreen/>
                    </div>
                    <div class="embed-responsive embed-responsive-16by9" v-if="showItem.docType == 'mp4'">
                        <iframe class="embed-responsive-item" :src="showItem.videoUrl" allowfullscreen/>
                    </div>
                    <img v-if="showItem.docType != 'mp4' && showItem.docType != 'pdf'? true: false" :src="showItem.thumbnailUrl" style="width: 100%; max-width: none">
                    <button id="btnDel" type="button" class="btn-close position-absolute" data-bs-dismiss="modal" data-bs-target="#detail_modal" aria-label="Close">
                    </button>
                </div>
            </div>
        </div>
    </div>
</Teleport>

</template>

<script>
import Global from "@/stores/GlobalVariables.js";
import axios from "axios";
import mixin from "@/components/Mixin";
import Pagination from "v-pagination-3";

export default {
    mixins: [mixin],
    name: 'DisplayUploadDocuments',
    components: { Pagination },
    data() {
        return {
            Global,
            ArrayImage: [],
            showItem: {},
            currentPage: 1,
            itemPerPage: 3,
            pageOfItems: [],
            paginateOptions: {
                hideCount: true,
                texts: {},
                edgeNavigation: false
            },
            maxDisplay: 20,
            tags: [
                "Uploaded by Customer",
                "Car registration book",
                "Driving license of main driver",
                "ID card",                
                "NCB"
            ],
            onloadImage: true
        }
    },
    props: {
        oppName: String,
        oppId: String,
        inspectionStatus: String,
        sfId: String
    },
    mounted() {       
        this.loadImage();
    },
    methods: {
        openPreviewModal: function (img) {
            var vm = this;
            vm.showItem = img;
            var modal = this.modal("#previewModal");
            if( modal ){
                modal.show();
            }
        },
        getData: function(){
            var vm = this;
            var tempArray = vm.ArrayImage;
            vm.pageOfItems = tempArray.slice((vm.currentPage-1)*vm.itemPerPage, vm.currentPage*vm.itemPerPage);
        },
        loadImage: async function(){
            let vm = this;
            const oppLv = await axios.get(Global.apiUrl + "/inspectionReport/details/" + vm.oppName + "/" + vm.oppId + "/" + vm.inspectionStatus);
            if(oppLv){
                let res = oppLv.data.vehicleInspectionPhotos.filter(item => item.tags.find(f => vm.tags.includes(f))) ;
                vm.ArrayImage = vm.ArrayImage.concat(res.slice(0, vm.maxDisplay));
            }
            const inspLv = await axios.get(Global.apiUrl + "/inspectionReport/details/" + vm.oppName + "/" + vm.sfId + "/" + vm.inspectionStatus);
            if(inspLv){
                let res = inspLv.data.vehicleInspectionPhotos.filter(item => item.tags.find(f => vm.tags.includes(f))) ;
                vm.ArrayImage = vm.ArrayImage.concat(res);
            }
            vm.ArrayImage = vm.ArrayImage.slice(0, vm.maxDisplay);
            if(vm.ArrayImage.length > vm.itemPerPage){
                vm.pageOfItems = vm.ArrayImage.slice((vm.currentPage-1)*vm.itemPerPage, vm.currentPage*vm.itemPerPage);
            }else{
                vm.pageOfItems = vm.ArrayImage;
            }
            vm.onloadImage = false;                       
        }
    }
}
</script>

<i18n>
    {
        "en": {
            "inspectionPhotoNotfound":  "Photos not found"
        },
        "th": {
            "inspectionPhotoNotfound":  "Photos not found"
        },
        "zh": {
            "inspectionPhotoNotfound":  "Photos not found"
        },
    }
</i18n>
